import { ApiPageRequestResponse, ApiRequestResponse } from "request/types";
import { getCommunicationApiUrl, request } from "request/nexupRequest";
import { HttpMethod, ModelId } from "@laba/ts-common";
import {
  ConnectStopWhatsappClientRequest,
  ConnectWhatsappClientRequest,
  DisconnectWhatsappClientRequest,
  WhatsappClient,
  WhatsappClientListQueryParams,
  WhatsappConfigureBackUpClientRequest
} from "model/communication";
import { urlJoin } from "url-join-ts";

const basePath = "/whatsapp-client";

export const getWhatsappClientList = (
  params: WhatsappClientListQueryParams
): Promise<ApiPageRequestResponse<WhatsappClient>> =>
  request({
    url: getCommunicationApiUrl(basePath),
    method: HttpMethod.GET,
    params
  });

export const getWhatsappClient = (
  id: ModelId
): Promise<ApiRequestResponse<WhatsappClient>> =>
  request({
    url: urlJoin(getCommunicationApiUrl(basePath), id),
    method: HttpMethod.GET
  });

export const createWhatsappClient = (
  data: WhatsappClient
): Promise<ApiRequestResponse<WhatsappClient>> =>
  request({
    url: getCommunicationApiUrl(basePath),
    method: HttpMethod.POST,
    data
  });

export const editWhatsappClient = (
  data: WhatsappClient
): Promise<ApiRequestResponse<WhatsappClient>> =>
  request({
    url: urlJoin(getCommunicationApiUrl(basePath), data.id),
    method: HttpMethod.PUT,
    data
  });

export const connectWhatsappClient = (
  data: ConnectWhatsappClientRequest
): Promise<ApiRequestResponse<WhatsappClient>> =>
  request({
    url: urlJoin(getCommunicationApiUrl(basePath), "connect"),
    method: HttpMethod.POST,
    data
  });

export const disconnectWhatsappClient = (
  data: DisconnectWhatsappClientRequest
): Promise<ApiRequestResponse<WhatsappClient>> =>
  request({
    url: urlJoin(getCommunicationApiUrl(basePath), "disconnect"),
    method: HttpMethod.POST,
    data
  });

export const stopQrWhatsappClient = (
  data: ConnectStopWhatsappClientRequest
): Promise<ApiRequestResponse<WhatsappClient>> =>
  request({
    url: urlJoin(getCommunicationApiUrl(basePath), "connect", "stop"),
    method: HttpMethod.POST,
    data
  });

export const configureBackupWhatsappClient = (
  data: WhatsappConfigureBackUpClientRequest
): Promise<ApiRequestResponse<WhatsappClient>> =>
  request({
    url: urlJoin(getCommunicationApiUrl(basePath), "backup"),
    method: HttpMethod.POST,
    data
  });
