import { FC, IconC } from "@laba/react-common";
import {
  AccountCircleIcon,
  ApartmentIcon,
  AssessmentIcon,
  BallotIcon,
  CalendarTodayIcon,
  DateRangeIcon,
  DevicesIcon,
  EcoIcon,
  LocalMallIcon,
  EventIcon,
  GroupIcon,
  HomeIcon,
  HotelIcon,
  ListAltIcon,
  ListIcon,
  LocalHospitalIcon,
  LocalPharmacyIcon,
  ScheduleIcon,
  SpeedIcon,
  WcIcon,
  WhatsAppIcon,
  Notifications,
  CreateNewFolderIcon,
  PaymentsIcon
} from "@laba/nexup-components";
import { PractitionerList } from "components/pages/NexupAdmin/resources/practitioner/PractitionerList";
import { PractitionerEdit } from "components/pages/NexupAdmin/resources/practitioner/PractitionerEdit";
import { PractitionerCreate } from "components/pages/NexupAdmin/resources/practitioner/PractitionerCreate";
import {
  ConceptResourceType,
  ExternalResourceType,
  RaResourceType,
  UserResourceType
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { ResourceType } from "@laba/nexup-api";
import { OrganizationList } from "components/pages/NexupAdmin/resources/organization/OrganizationList";
import { OrganizationCreate } from "components/pages/NexupAdmin/resources/organization/OrganizationCreate";
import { OrganizationEdit } from "components/pages/NexupAdmin/resources/organization/OrganizationEdit";
import { LocationList } from "components/pages/NexupAdmin/resources/location/LocationList";
import { LocationCreate } from "components/pages/NexupAdmin/resources/location/LocationCreate";
import { LocationEdit } from "components/pages/NexupAdmin/resources/location/LocationEdit";
import { CodeSystemList } from "components/pages/NexupAdmin/resources/codeSystem/CodeSystemList";
import { CodeSystemCreate } from "components/pages/NexupAdmin/resources/codeSystem/CodeSystemCreate";
import { CodeSystemEdit } from "components/pages/NexupAdmin/resources/codeSystem/CodeSystemEdit";
import { PatientList } from "components/pages/NexupAdmin/resources/patient/PatientList";
import { PatientCreate } from "components/pages/NexupAdmin/resources/patient/PatientCreate";
import { PatientEdit } from "components/pages/NexupAdmin/resources/patient/PatientEdit";
import { HealthcareServiceList } from "components/pages/NexupAdmin/resources/healthcareService/HealthcareServiceList";
import { HealthcareServiceCreate } from "components/pages/NexupAdmin/resources/healthcareService/HealthcareServiceCreate";
import { HealthcareServiceEdit } from "components/pages/NexupAdmin/resources/healthcareService/HealthcareServiceEdit";
import { AppointmentDefinitionCreate } from "components/pages/NexupAdmin/resources/appointmentDefinition/AppointmentDefinitionCreate";
import { AppointmentDefinitionEdit } from "components/pages/NexupAdmin/resources/appointmentDefinition/AppointmentDefinitionEdit";
import { AppointmentDefinitionList } from "components/pages/NexupAdmin/resources/appointmentDefinition/AppointmentDefinitionList";
import { HospitalizationList } from "components/pages/NexupAdmin/resources/hospitalization/HospitalizationList";
import { HospitalizationCreate } from "components/pages/NexupAdmin/resources/hospitalization/HospitalizationCreate";
import { HospitalizationEdit } from "components/pages/NexupAdmin/resources/hospitalization/HospitalizationEdit";
import { QuestionnaireList } from "components/pages/NexupAdmin/resources/questionnaire/QuestionnaireList";
import { QuestionnaireCreate } from "components/pages/NexupAdmin/resources/questionnaire/QuestionnaireCreate";
import { QuestionnaireEdit } from "components/pages/NexupAdmin/resources/questionnaire/QuestionnaireEdit";
import { MedicalDeviceEdit } from "components/pages/NexupAdmin/resources/medicalDevice/MedicalDeviceEdit";
import { MedicalDeviceList } from "components/pages/NexupAdmin/resources/medicalDevice/MedicalDeviceList";
import { MedicalDeviceCreate } from "components/pages/NexupAdmin/resources/medicalDevice/MedicalDeviceCreate";
import { MedicationSubstanceList } from "components/pages/NexupAdmin/resources/medicationSubstance/MedicationSubstanceList";
import { MedicationSubstanceEdit } from "components/pages/NexupAdmin/resources/medicationSubstance/MedicationSubstanceEdit";
import { MedicationSubstanceCreate } from "components/pages/NexupAdmin/resources/medicationSubstance/MedicationSubstanceCreate";
import { MedicationList } from "components/pages/NexupAdmin/resources/medication/MedicationList";
import { MedicationEdit } from "components/pages/NexupAdmin/resources/medication/MedicationEdit";
import { MedicationCreate } from "components/pages/NexupAdmin/resources/medication/MedicationCreate";
import { MedicalRequestDefinitionList } from "components/pages/NexupAdmin/resources/medicalRequestDefinition/MedicalRequestDefinitionList";
import { MedicalRequestDefinitionCreate } from "components/pages/NexupAdmin/resources/medicalRequestDefinition/MedicalRequestDefinitionCreate";
import { MedicalRequestDefinitionEdit } from "components/pages/NexupAdmin/resources/medicalRequestDefinition/MedicalRequestDefinitionEdit";
import { AppointmentList } from "components/pages/NexupAdmin/resources/appointment/AppointmentList";
import { AppointmentEdit } from "components/pages/NexupAdmin/resources/appointment/AppointmentEdit";
import { AppointmentCreate } from "components/pages/NexupAdmin/resources/appointment/AppointmentCreate";
import { UserList } from "components/pages/NexupAdmin/resources/user/UserList";
import { UserCreate } from "components/pages/NexupAdmin/resources/user/UserCreate";
import { UserEdit } from "components/pages/NexupAdmin/resources/user/UserEdit";
import { MeasurementGroupDefinitionList } from "components/pages/NexupAdmin/resources/measurementGroupDefinition/MeasurementGroupDefinitionList";
import { MeasurementGroupDefinitionCreate } from "components/pages/NexupAdmin/resources/measurementGroupDefinition/MeasurementGroupDefinitionCreate";
import { MeasurementGroupDefinitionEdit } from "components/pages/NexupAdmin/resources/measurementGroupDefinition/MeasurementGroupDefinitionEdit";
import {
  AdminPanelPermissions,
  AdminPanelSpecialPermissions
} from "models/permissions/adminPanelPermissions";
import { ProcedureReportDefinitionCreate } from "components/pages/NexupAdmin/resources/procedureReportDefinition/ProcedureReportDefinitionCreate";
import { ProcedureReportDefinitionList } from "components/pages/NexupAdmin/resources/procedureReportDefinition/ProcedureReportDefinitionList";
import { ProcedureReportDefinitionEdit } from "components/pages/NexupAdmin/resources/procedureReportDefinition/ProcedureReportDefinitionEdit";
import { ScheduleDefinitionCreate } from "components/pages/NexupAdmin/resources/scheduleDefinition/ScheduleDefinitionCreate";
import { ScheduleDefinitionEdit } from "components/pages/NexupAdmin/resources/scheduleDefinition/ScheduleDefinitionEdit";
import { ScheduleDefinitionList } from "components/pages/NexupAdmin/resources/scheduleDefinition/ScheduleDefinitionList";
import { ScheduleCreate } from "components/pages/NexupAdmin/resources/schedule/ScheduleCreate";
import { ScheduleEdit } from "components/pages/NexupAdmin/resources/schedule/ScheduleEdit";
import { ScheduleList } from "components/pages/NexupAdmin/resources/schedule/ScheduleList";
import { ProcedureCodeConceptCreate } from "components/pages/NexupAdmin/resources/concept/procedureCodeConceptList/ProcedureCodeConceptCreate";
import { ProcedureCodeConceptEdit } from "components/pages/NexupAdmin/resources/concept/procedureCodeConceptList/ProcedureCodeConceptEdit";
import { ProcedureCodeConceptList } from "components/pages/NexupAdmin/resources/concept/procedureCodeConceptList/ProcedureCodeConceptList";
import { ProcedureBatteryConceptList } from "components/pages/NexupAdmin/resources/concept/batteryProcedureCode/ProcedureBatteryConceptList";
import { ProcedureBatteryConceptCreate } from "components/pages/NexupAdmin/resources/concept/batteryProcedureCode/ProcedureBatteryConceptCreate";
import { ProcedureBatteryConceptEdit } from "components/pages/NexupAdmin/resources/concept/batteryProcedureCode/ProcedureBatteryConceptEdit";
import { WorkspaceAdministration } from "components/pages/WorkspaceAdministration/WorkspaceAdministration";
import { tkCP } from "translation/i18n";
import { NotificationList } from "./resources/notification/NotificationList";
import { NotificationEdit } from "./resources/notification/NotificationEdit";
import { NotificationCreate } from "./resources/notification/NotificationCreate";
import { WhatsappNotificationList } from "./resources/whatsappNotification/WhatsappNotificationList";
import { WhatsappNotificationCreate } from "./resources/whatsappNotification/WhatsappNotificationCreate";
import { WhatsappNotificationEdit } from "./resources/whatsappNotification/WhatsappNotificationEdit";
import { ProductList } from "./resources/product/ProductList";
import { ProductCreate } from "./resources/product/ProductCreate";
import { ProductEdit } from "./resources/product/ProductEdit";
import { AccountList } from "./resources/account/AccountList";
import { AccountCreate } from "./resources/account/AccountCreate";
import { AccountEdit } from "./resources/account/AccountEdit";
import { WhatsappClientList } from "./resources/whatsappClient/WhatsappClientList";
import { WhatsappClientCreate } from "./resources/whatsappClient/WhatsappClientCreate";
import { WhatsappClientEdit } from "./resources/whatsappClient/WhatsappClientEdit";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PageComponent = FC<any>;
export interface ResourceConfig {
  resource: RaResourceType;
  list: PageComponent;
  create?: PageComponent;
  edit?: PageComponent;
  icon: IconC;
  permissionList?: AdminPanelPermissions[];
}

export const configList: ResourceConfig[] = [
  {
    resource: ResourceType.Organization,
    list: OrganizationList,
    create: OrganizationCreate,
    edit: OrganizationEdit,
    icon: HomeIcon
  },
  {
    resource: UserResourceType,
    list: UserList,
    create: UserCreate,
    edit: UserEdit,
    icon: AccountCircleIcon
  },
  {
    resource: ResourceType.Practitioner,
    list: PractitionerList,
    create: PractitionerCreate,
    edit: PractitionerEdit,
    icon: GroupIcon
  },
  {
    resource: ResourceType.Patient,
    list: PatientList,
    create: PatientCreate,
    edit: PatientEdit,
    icon: WcIcon
  },
  {
    resource: ResourceType.Hospitalization,
    list: HospitalizationList,
    create: HospitalizationCreate,
    edit: HospitalizationEdit,
    icon: HotelIcon
  },
  {
    resource: ResourceType.Appointment,
    list: AppointmentList,
    create: AppointmentCreate,
    edit: AppointmentEdit,
    icon: EventIcon
  },
  {
    resource: ResourceType.Location,
    list: LocationList,
    create: LocationCreate,
    edit: LocationEdit,
    icon: ApartmentIcon
  },
  {
    resource: ResourceType.CodeSystem,
    list: CodeSystemList,
    create: CodeSystemCreate,
    edit: CodeSystemEdit,
    icon: ListIcon
  },
  {
    resource: ResourceType.HealthcareService,
    list: HealthcareServiceList,
    create: HealthcareServiceCreate,
    edit: HealthcareServiceEdit,
    icon: LocalHospitalIcon
  },
  {
    resource: ResourceType.MedicalDevice,
    list: MedicalDeviceList,
    create: MedicalDeviceCreate,
    edit: MedicalDeviceEdit,
    icon: DevicesIcon
  },
  {
    resource: ResourceType.MedicationSubstance,
    list: MedicationSubstanceList,
    create: MedicationSubstanceCreate,
    edit: MedicationSubstanceEdit,
    icon: EcoIcon
  },
  {
    resource: ResourceType.Medication,
    list: MedicationList,
    create: MedicationCreate,
    edit: MedicationEdit,
    icon: LocalPharmacyIcon
  },
  {
    resource: ResourceType.MedicalRequestDefinition,
    list: MedicalRequestDefinitionList,
    create: MedicalRequestDefinitionCreate,
    edit: MedicalRequestDefinitionEdit,
    icon: ListAltIcon
  },
  {
    resource: ResourceType.Questionnaire,
    list: QuestionnaireList,
    create: QuestionnaireCreate,
    edit: QuestionnaireEdit,
    icon: BallotIcon
  },
  {
    resource: ResourceType.AppointmentDefinition,
    list: AppointmentDefinitionList,
    create: AppointmentDefinitionCreate,
    edit: AppointmentDefinitionEdit,
    icon: DateRangeIcon
  },
  {
    resource: ResourceType.MeasurementGroupDefinition,
    list: MeasurementGroupDefinitionList,
    create: MeasurementGroupDefinitionCreate,
    edit: MeasurementGroupDefinitionEdit,
    icon: SpeedIcon
  },
  {
    resource: ResourceType.ProcedureReportDefinition,
    list: ProcedureReportDefinitionList,
    create: ProcedureReportDefinitionCreate,
    edit: ProcedureReportDefinitionEdit,
    icon: AssessmentIcon
  },
  {
    resource: ResourceType.ScheduleDefinition,
    list: ScheduleDefinitionList,
    create: ScheduleDefinitionCreate,
    edit: ScheduleDefinitionEdit,
    icon: CalendarTodayIcon
  },
  {
    resource: ResourceType.Schedule,
    list: ScheduleList,
    create: ScheduleCreate,
    edit: ScheduleEdit,
    icon: ScheduleIcon
  },
  {
    resource: ConceptResourceType.ProcedureCodeConcept,
    list: ProcedureCodeConceptList,
    create: ProcedureCodeConceptCreate,
    edit: ProcedureCodeConceptEdit,
    icon: ListIcon
  },
  {
    resource: ConceptResourceType.ProcedureBatteryConcept,
    list: ProcedureBatteryConceptList,
    create: ProcedureBatteryConceptCreate,
    edit: ProcedureBatteryConceptEdit,
    icon: ListIcon
  },
  {
    resource: ExternalResourceType.Notification,
    list: NotificationList,
    create: NotificationCreate,
    edit: NotificationEdit,
    icon: Notifications
  },
  {
    resource: ExternalResourceType.WhatsappNotification,
    list: WhatsappNotificationList,
    create: WhatsappNotificationCreate,
    edit: WhatsappNotificationEdit,
    icon: WhatsAppIcon
  },
  {
    resource: ResourceType.Product,
    list: ProductList,
    create: ProductCreate,
    edit: ProductEdit,
    icon: LocalMallIcon
  },
  {
    resource: ResourceType.Account,
    list: AccountList,
    create: AccountCreate,
    edit: AccountEdit,
    icon: PaymentsIcon
  },
  {
    resource: ExternalResourceType.WhatsappClient,
    list: WhatsappClientList,
    create: WhatsappClientCreate,
    edit: WhatsappClientEdit,
    icon: WhatsAppIcon
  }
];

export interface CustomRouteConfig {
  path: string;
  titleKey: string;
  icon: IconC;
  component: PageComponent;
  permissionList: AdminPanelPermissions[];
}

export const customConfigList: CustomRouteConfig[] = [
  {
    path: "workspace-administration",
    component: WorkspaceAdministration,
    titleKey: tkCP.adminPage.dashboard.workspaceAdministration.title,
    icon: CreateNewFolderIcon,
    permissionList: [AdminPanelSpecialPermissions.WorkspaceAdministration]
  }
];
