import { NotificationStatus } from "model/communication/notification/notificationStatus";
import { ReceiverType } from "model/communication/notification/receiverType";
import { LogicType } from "model/communication/notification/logicType";
import { ListQueryParams } from "request/types";
import { createHydratedMock } from "ts-auto-mock";
import { ApiDate, BaseVersionedModel, getKeyObj } from "@laba/ts-common";
import { keys } from "lodash-es";
import { NotificationFhirData } from "model/communication/notificationFhirData";
import { AppointmentNotificationActionCode } from "model/primitives/notificationConfigActionCode";

export enum NotificationOrder {
  CreationDate = "creationDate",
  UpdateDate = "lastUpdatedDate"
}

export interface Notification extends BaseVersionedModel, NotificationFhirData {
  status?: NotificationStatus;
  senderId?: string;
  receiver?: string;
  receiverType?: ReceiverType;
  messageTitle?: string;
  messageBody?: string;
  sessionInitMessage?: string;
  logicType?: LogicType;
  mediaUrl?: string[];
  notificationActionCode?: AppointmentNotificationActionCode;
  scheduledDate?: ApiDate;
}

export type NotificationLastVersion = Notification;

export interface NotificationListQueryParams extends ListQueryParams {
  status?: string;
  organizationId?: string;
  patientId?: string;
  practitionerId?: string;
  appointmentId?: string;
  receiverType?: string;
  receiver?: string;
  senderId?: string;
  order?: NotificationOrder;
}

export const NotificationParamsKey = getKeyObj<Notification>(
  createHydratedMock<Notification>()
);

export const NotificationLastVersionParamsKey =
  getKeyObj<NotificationLastVersion>(
    createHydratedMock<NotificationLastVersion>()
  );

export const NotificationListQueryParamsKey =
  getKeyObj<NotificationListQueryParams>(
    createHydratedMock<NotificationListQueryParams>()
  );

export const NotificationKeysList = keys(NotificationParamsKey);

export const isPhoneNotification = (notification: Notification): boolean =>
  notification.receiverType === ReceiverType.Phone;

export const isEmailNotification = (notification: Notification): boolean =>
  notification.receiverType === ReceiverType.Email;
