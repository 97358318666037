import {
  ProviderCreate,
  ProviderDelete,
  ProviderDeleteMany,
  ProviderGetList,
  ProviderGetMany,
  ProviderGetManyReference,
  ProviderGetOne,
  ProviderUpdate,
  ProviderUpdateMany,
  ResourceProviderTypes
} from "providers/dataProvider/resourceProvider/utils/resourceProviderTypes";
import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  Model,
  ModelId,
  ResourceType
} from "@laba/nexup-api";
import {
  getEnumOrUndefined,
  KeyObj,
  Optional,
  RequestFailureStatus
} from "@laba/ts-common";
import { HttpError } from "react-admin";
import produce from "immer";

export interface BaseQParams {
  page?: number;
  pageSize?: number;
  order?: string;
  id?: ModelId[];
}

type OrganizationFilterGetter<QParams> = (
  isManyGetter?: boolean
) => Optional<QParams>;

export const getListGetter =
  <T extends Model, QParams extends BaseQParams>(
    getList: (query: QParams) => Promise<ApiPageRequestResponse<T>>,
    getOrderParam: (field: string, order: string) => Optional<string>,
    organizationFilterGetter?: OrganizationFilterGetter<QParams>
  ): ProviderGetList<T, QParams> =>
  async params => {
    const response = await getList({
      ...params.meta?.extraParams,
      ...organizationFilterGetter?.(),
      ...params.filter,
      page: params.pagination.page,
      pageSize: params.pagination.perPage,
      order: getOrderParam(params.sort.field, params.sort.order)
    });
    if (response.failureStatus === RequestFailureStatus.Failure) {
      throw new HttpError(response.errorMsg, response.status, response.data);
    }
    return {
      total: response.data.page.totalEntries,
      data: response.data.entries
    };
  };

export const getOneGetter =
  <T extends Model, QParams extends BaseQParams>(
    getOne: (id: ModelId, query: QParams) => Promise<ApiRequestResponse<T>>
  ): ProviderGetOne<T> =>
  async params => {
    const response = await getOne(
      params.id.toString(),
      params.meta?.extraParams as unknown as QParams
    );
    if (response.failureStatus === RequestFailureStatus.Failure) {
      throw new HttpError(response.errorMsg, response.status, response.data);
    }
    return {
      data: response.data
    };
  };

export const getManyGetter =
  <T extends Model, QParams extends BaseQParams>(
    getList: (query: QParams) => Promise<ApiPageRequestResponse<T>>,
    organizationFilterGetter?: OrganizationFilterGetter<QParams>
  ): ProviderGetMany<T> =>
  async params => {
    const response = await getList({
      ...params.meta?.extraParams,
      ...organizationFilterGetter?.(true),
      id: params.ids.map(String),
      pageSize: params.ids.length
    } as QParams);
    if (response.failureStatus === RequestFailureStatus.Failure) {
      throw new HttpError(response.errorMsg, response.status, response.data);
    }
    return {
      data: response.data.entries
    };
  };

export const getManyReferenceGetter =
  <T extends Model, QParams extends BaseQParams>(
    getList: (query: QParams) => Promise<ApiPageRequestResponse<T>>,
    getOrderParam: (field: string, order: string) => Optional<string>,
    getTargetParams: (
      target: string,
      id: ModelId
    ) => Optional<Partial<QParams>>,
    organizationFilterGetter?: OrganizationFilterGetter<QParams>
  ): ProviderGetManyReference<T, QParams> =>
  async params => {
    const query: QParams = {
      ...params.meta?.extraParams,
      ...organizationFilterGetter?.(true),
      ...params.filter,
      ...getTargetParams(params.target, String(params.id)),
      pageSize: params.pagination.perPage,
      page: params.pagination.page,
      order: getOrderParam(params.sort.field, params.sort.order)
    };
    const response = await getList(query);
    if (response.failureStatus === RequestFailureStatus.Failure) {
      throw new HttpError(response.errorMsg, response.status, response.data);
    }
    return {
      data: response.data.entries,
      total: response.data.page.totalEntries
    };
  };

export const updateGetter =
  <T extends Model>(
    update: (data: T) => Promise<ApiRequestResponse<T>>,
    onSave?: (data: T) => void
  ): ProviderUpdate<T> =>
  async params => {
    if (params.id !== params.data.id) {
      throw new HttpError("update with id inconsistency", -1);
    }
    const response = await update(params.data);
    if (response.failureStatus === RequestFailureStatus.Failure) {
      throw new HttpError(response.errorMsg, response.status, response.data);
    }
    onSave?.(response.data);
    return {
      data: response.data
    };
  };

export const updateManyGetter =
  <T extends Model>(
    update: (data: T) => Promise<ApiRequestResponse<T>>,
    onSave?: (data: T) => void
  ): ProviderUpdateMany<T> =>
  async params => {
    const updatedIdList: ModelId[] = [];
    await Promise.all(
      params.ids.map(async id => {
        const model = produce(params.data, draftModel => {
          draftModel.id = String(id);
        });
        const response = await update(model);
        if (response.failureStatus === RequestFailureStatus.Success) {
          onSave?.(response.data);
          updatedIdList.push(String(id));
        }
      })
    );
    return {
      data: updatedIdList
    };
  };

export const createGetter =
  <T extends Model>(
    create: (data: T) => Promise<ApiRequestResponse<T>>,
    onSave?: (data: T) => void
  ): ProviderCreate<T> =>
  async params => {
    const response = await create(params.data);
    if (response.failureStatus === RequestFailureStatus.Failure) {
      throw new HttpError(response.errorMsg, response.status, response.data);
    }
    onSave?.(response.data);
    return {
      data: response.data
    };
  };

export const deleteGetter =
  <T extends Model>(): ProviderDelete<T> =>
  async params => {
    throw new HttpError(`no delete ${params.id}`, -1);
  };

export const deleteManyGetter =
  <T extends Model>(): ProviderDeleteMany<T> =>
  async params => {
    throw new HttpError(`no delete many ${params.ids}`, -1);
  };

export const UserResourceType = "User";
export enum ExternalResourceType {
  User = "User",
  Notification = "Notification",
  WhatsappNotification = "WhatsappNotification",
  WhatsappClient = "WhatsappClient",
  WhatsappNotificationHistory = "WhatsappNotificationHistory"
}

export enum ConceptResourceType {
  ProcedureCodeConcept = "ProcedureCodeConcept",
  ProcedureBatteryConcept = "ProcedureBatteryConcept"
}
export type RaConceptResourceType =
  | ConceptResourceType.ProcedureCodeConcept
  | ConceptResourceType.ProcedureBatteryConcept;
export type RaResourceType =
  | ResourceType
  | typeof UserResourceType
  | ExternalResourceType
  | RaConceptResourceType;

export const resourceProviderGetter = <
  T extends Model,
  QParams extends BaseQParams
>(
  resourceType: RaResourceType,
  getOrderParam: (field: string, order: string) => Optional<string>,
  getTargetParams: (target: string, id: ModelId) => Optional<Partial<QParams>>,
  getList: (query: QParams) => Promise<ApiPageRequestResponse<T>>,
  getOne: (id: ModelId, query: QParams) => Promise<ApiRequestResponse<T>>,
  update: (data: T) => Promise<ApiRequestResponse<T>>,
  create: (data: T) => Promise<ApiRequestResponse<T>>,
  onSave?: (data: T) => void,
  organizationFilterGetter?: OrganizationFilterGetter<QParams>
): ResourceProviderTypes<T, QParams> => ({
  resourceType,
  getList: getListGetter(getList, getOrderParam, organizationFilterGetter),
  getOne: getOneGetter(getOne),
  getMany: getManyGetter(getList, organizationFilterGetter),
  getManyReference: getManyReferenceGetter(
    getList,
    getOrderParam,
    getTargetParams,
    organizationFilterGetter
  ),
  update: updateGetter(update, onSave),
  updateMany: updateManyGetter(update, onSave),
  create: createGetter(create, onSave),
  delete: deleteGetter(),
  deleteMany: deleteManyGetter()
});

export const getEnumOrderParam =
  <Enum extends Record<string, string>>(e: Enum) =>
  (field: string): Optional<string> =>
    getEnumOrUndefined(e)(field);

export const getKeyTargetParam =
  <QParams>(keyObj: KeyObj<QParams>) =>
  (target: string, id: ModelId): Optional<Partial<QParams>> => {
    if (Object.values(keyObj).includes(target)) {
      return {
        [target]: id
      } as unknown as Partial<QParams>;
    }
  };
