import React, { useCallback, useState } from "react";
import {
  WhatsappClient,
  WhatsappClientConnectionStatus
} from "@laba/nexup-api";
import { FC, useI18n } from "@laba/react-common";
import { Grid, Typography } from "@mui/material";
import { tkCP } from "translation/i18n";
import { DefaultButton, SwitchToggle, TextInput } from "@laba/nexup-components";
import { GridAlignItems, Optional } from "@laba/ts-common";
import {
  onConfigureWhatsappClientBackup,
  onConnectWhatsappClient,
  onDisconnectWhatsappClient,
  onSearchWhatsappClientById,
  onStopQrWhatsappClient
} from "store/dashboard/event";
import { useAppDispatch } from "store/store";

interface Props {
  setLoading: (loading: boolean) => void;
}
const tk = tkCP.adminPage.dashboard.whatsappSession.item;

export const WhatsappActionSection: FC<Props> = ({ setLoading }) => {
  const [searchId, setSearchId] = useState<Optional<string>>();
  const [whatsappClient, setWhatsappClient] =
    useState<Optional<WhatsappClient>>();
  const { t } = useI18n();

  const dispatch = useAppDispatch();

  const onSearchClicked = useCallback(async () => {
    if (!searchId) {
      return;
    }
    setLoading(true);
    const res = await dispatch(onSearchWhatsappClientById(searchId));
    setWhatsappClient(res);

    setLoading(false);
  }, [dispatch, searchId, setLoading]);

  const onConnectClicked = useCallback(async () => {
    setLoading(true);
    const res = await dispatch(
      onConnectWhatsappClient({
        senderId: whatsappClient?.senderId,
        organizationId: whatsappClient?.organizationId
      })
    );
    setWhatsappClient(res);

    setLoading(false);
  }, [
    dispatch,
    whatsappClient?.senderId,
    whatsappClient?.organizationId,
    setLoading
  ]);

  const onDisconnectClicked = useCallback(async () => {
    setLoading(true);
    const res = await dispatch(
      onDisconnectWhatsappClient({ senderId: whatsappClient?.senderId })
    );
    setWhatsappClient(res);

    setLoading(false);
  }, [dispatch, whatsappClient?.senderId, setLoading]);

  const onStopClicked = useCallback(async () => {
    setLoading(true);
    const res = await dispatch(
      onStopQrWhatsappClient({ senderId: whatsappClient?.senderId })
    );
    setWhatsappClient(res);

    setLoading(false);
  }, [dispatch, whatsappClient?.senderId, setLoading]);

  const onBackupConfigurationChange = useCallback(async () => {
    setLoading(true);
    const res = await dispatch(
      onConfigureWhatsappClientBackup({
        senderId: whatsappClient?.senderId,
        enable: !whatsappClient?.backupEnabled
      })
    );
    setWhatsappClient(res);

    setLoading(false);
  }, [
    dispatch,
    whatsappClient?.senderId,
    whatsappClient?.backupEnabled,
    setLoading
  ]);

  return (
    <Grid container columnSpacing={3} rowSpacing={2}>
      <Grid item>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          alignItems={GridAlignItems.End}
        >
          <Grid item>
            <Typography title={t(tk.senderIdTitle)}>
              {t(tk.senderIdTitle)}
            </Typography>
            <TextInput value={searchId} onChange={setSearchId} />
          </Grid>
          <Grid item>
            <DefaultButton
              title={t(tk.searchButton)}
              text={t(tk.searchButton)}
              onClick={onSearchClicked}
              disabled={!searchId}
            />
          </Grid>
        </Grid>
      </Grid>
      {whatsappClient && (
        <Grid item>
          <Typography>
            {t(tk.organizationId, { id: whatsappClient.organizationId })}
          </Typography>
          <Typography>
            {t(tk.senderId, { id: whatsappClient.senderId })}
          </Typography>
          <Typography>
            {t(tk.statusTitle, { status: whatsappClient.connectionStatus })}
          </Typography>
          <Typography>
            {t(tk.phoneNumber, { phone: whatsappClient.phone })}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          alignItems={GridAlignItems.End}
        >
          <Grid item>
            <DefaultButton
              title={t(tk.connectButton)}
              text={t(tk.connectButton)}
              onClick={onConnectClicked}
              disabled={
                !whatsappClient ||
                whatsappClient.connectionStatus ===
                  WhatsappClientConnectionStatus.Connected
              }
            />
          </Grid>
          <Grid item>
            <DefaultButton
              title={t(tk.disconnectButton)}
              text={t(tk.disconnectButton)}
              onClick={onDisconnectClicked}
              disabled={
                !whatsappClient ||
                whatsappClient.connectionStatus ===
                  WhatsappClientConnectionStatus.Disconnected
              }
            />
          </Grid>
          <Grid item>
            <DefaultButton
              title={t(tk.stopButton)}
              text={t(tk.stopButton)}
              onClick={onStopClicked}
              disabled={!whatsappClient}
            />
          </Grid>
          <Grid item>
            <SwitchToggle
              checked={whatsappClient?.backupEnabled}
              title={t(tk.backup)}
              text={t(tk.backup)}
              onChange={onBackupConfigurationChange}
              disabled={!whatsappClient}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
