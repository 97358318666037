export const whatsappClient = {
  title: "Cliente Whatsapp",
  fields: {
    id: "Id",
    version: "Version",
    senderId: "Remitente Id",
    organizationId: "Organización Id",
    phone: "Telefono",
    enabled: "Habilitado",
    connectionStatus: "Estado de conexión",
    backupEnabled: "Backup habilitado",
    lastUpdateDate: "Ultima fecha actualizacion",
    status: "Estado"
  },
  filters: {
    connectionStatus: "Estado de conexión",
    enabled: "Habilitado",
    organizationId: "Organización Id",
    phone: "Telefono",
    senderId: "Remitente Id",
    order: "Orden"
  },
  tabs: {
    main: "Cliente whatsappp"
  }
};
