import React from "react";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { List } from "components/generic/List/List";
import { FC, useI18n } from "@laba/react-common";
import { TextInput } from "components/generic/TextInput/TextInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { tkCP } from "translation/i18n";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { ExternalResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { TextField } from "components/generic/TextField/TextField";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import {
  WhatsappClientConnectionStatus,
  WhatsappClientListQueryParamsKey,
  WhatsappClientOrder,
  WhatsappClientParamsKey
} from "@laba/nexup-api";
import { BooleanField } from "react-admin";
import { whatsappClientJsonToModel } from "components/pages/NexupAdmin/resources/whatsappClient/whatsappClientForm/WhatsappClientCreateEditForm";

const tk = tkCP.adminPage[ExternalResourceType.WhatsappClient];

export const WhatsappClientList: FC = () => {
  const { t } = useI18n();
  const exportedFileName = useListExportedFileName(t(tk.title));

  return (
    <List
      resource={ExternalResourceType.WhatsappClient}
      jsonToModel={whatsappClientJsonToModel}
      exportedFileName={exportedFileName}
      filterDefaultValues={{
        [WhatsappClientListQueryParamsKey.enabled]: true
      }}
      extraFilters={[
        <EnumSelectorInput
          enumValue={WhatsappClientConnectionStatus}
          source={WhatsappClientListQueryParamsKey.connectionStatus}
          label={t(tk.filters.connectionStatus)}
          alwaysOn
          resettable
        />,
        <BooleanInput
          source={WhatsappClientListQueryParamsKey.enabled}
          label={t(tk.filters.enabled)}
          alwaysOn
        />,
        <TextInput
          source={WhatsappClientListQueryParamsKey.organizationId}
          label={t(tk.filters.organizationId)}
          alwaysOn
          clearAlwaysVisible
          resettable
        />,
        <TextInput
          source={WhatsappClientListQueryParamsKey.phone}
          label={t(tk.filters.phone)}
          alwaysOn
          clearAlwaysVisible
          resettable
        />,
        <TextInput
          source={WhatsappClientListQueryParamsKey.senderId}
          label={t(tk.filters.senderId)}
          alwaysOn
          clearAlwaysVisible
          resettable
        />,
        <EnumSelectorInput
          label={t(tk.filters.order)}
          source={WhatsappClientListQueryParamsKey.order}
          enumValue={WhatsappClientOrder}
          alwaysOn
          resettable
        />
      ]}
    >
      <Datagrid>
        <TextField
          source={WhatsappClientParamsKey.id}
          label={t(tk.fields.id)}
        />
        <TextField
          source={WhatsappClientParamsKey.version}
          label={t(tk.fields.version)}
        />

        <TextField
          source={WhatsappClientParamsKey.senderId}
          label={t(tk.fields.senderId)}
        />
        <TextField
          source={WhatsappClientParamsKey.organizationId}
          label={t(tk.fields.organizationId)}
        />
        <TextField
          source={WhatsappClientParamsKey.phone}
          label={t(tk.fields.phone)}
        />
        <BooleanField
          source={WhatsappClientParamsKey.enabled}
          label={t(tk.fields.enabled)}
        />
        <TextField
          source={WhatsappClientParamsKey.connectionStatus}
          label={t(tk.fields.connectionStatus)}
        />
        <BooleanField
          source={WhatsappClientParamsKey.backupEnabled}
          label={t(tk.fields.backupEnabled)}
        />
      </Datagrid>
    </List>
  );
};
