export enum NotificationConfigActionCode {
  MedicalRequestCancel = "medical-request-cancel",
  MedicalRequestCreate = "medical-request-create",
  MedicalRequestEdit = "medical-request-edit",
  MedicalRequestExpiration = "medical-request-expiration",
  HospitalizationPlanned = "hospitalization-planned",
  HospitalizationBedChange = "hospitalization-bed-change",
  PasswordResetHis = "password-reset-his",
  PasswordResetPatient = "password-reset-patient",
  PatientAdmission = "patient-admission",
  PatientDischarge = "patient-discharge",
  ProcedureReportAction = "procedure-report-action",
  SendPrescriptionRequestFile = "send-prescription-request-file",
  PatientRegisterInvite = "PatientRegisterInvite",
  CreatePassword = "CreatePassword",
  ResetPasswordPatient = "ResetPasswordPatient",
  ResetPasswordPractitioner = "ResetPasswordPractitioner",
  ProductExport = "ProductExport",
  SendPatientInvoiceFile = "SendPatientInvoiceFile",
  UserRegistrationPractitioner = "UserRegistrationPractitioner",
  WorkspaceCreation = "WorkspaceCreation",
  PractitionerInvitation = "PractitionerInvitation",
  PractitionerRegistrationValidated = "PractitionerRegistrationValidated",
  PractitionerRegistrationRejected = "PractitionerRegistrationRejected",
  PatientExport = "PatientExport",
  PatientImport = "PatientImport",
  ProductImport = "ProductImport",
  PractitionerExport = "PractitionerExport",
  PractitionerImport = "PractitionerImport",
  WhatsAppDisconected = "WhatsAppDisconected",
  LocationImport = "LocationImport",
  ProviderAccountTransactionExport = "ProviderAccountTransactionExport",

  AppointmentCreateForPatient = "AppointmentCreateForPatient",
  AppointmentCreateForPractitioner = "AppointmentCreateForPractitioner",
  AppointmentCreateSeriesForPatient = "AppointmentCreateSeriesForPatient",
  AppointmentCreateSeriesForPractitioner = "AppointmentCreateSeriesForPractitioner",
  AppointmentEditForPatient = "AppointmentEditForPatient",
  AppointmentEditForPractitioner = "AppointmentEditForPractitioner",
  AppointmentEditSeriesForPatient = "AppointmentEditSeriesForPatient",
  AppointmentEditSeriesForPractitioner = "AppointmentEditSeriesForPractitioner",
  AppointmentEditPatientDataForPatient = "AppointmentEditPatientDataForPatient",
  AppointmentEditSeriesPatientDataForPatient = "AppointmentEditSeriesPatientDataForPatient",
  AppointmentCancelForPatient = "AppointmentCancelForPatient",
  AppointmentCancelForPractitioner = "AppointmentCancelForPractitioner",
  AppointmentCancelSeriesForPatient = "AppointmentCancelSeriesForPatient",
  AppointmentCancelSeriesForPractitioner = "AppointmentCancelSeriesForPractitioner",
  AppointmentAdmitForPatient = "AppointmentAdmitForPatient",
  AppointmentAdmitForPractitioner = "AppointmentAdmitForPractitioner",
  AppointmentAttendForPatient = "AppointmentAttendForPatient",
  AppointmentAttendForPractitioner = "AppointmentAttendForPractitioner",
  AppointmentFinishForPatient = "AppointmentFinishForPatient",
  AppointmentFinishForPractitioner = "AppointmentFinishForPractitioner",
  AppointmentReminderForPatient = "AppointmentReminderForPatient",
  AppointmentReminderForPractitioner = "AppointmentReminderForPractitioner",
  AppointmentStartingReminderForPatient = "AppointmentStartingReminderForPatient",
  AppointmentStartingReminderForPractitioner = "AppointmentStartingReminderForPractitioner",
  AppointmentConfirmationReminderForPatient = "AppointmentConfirmationReminderForPatient",
  AppointmentConfirmForPatient = "AppointmentConfirmForPatient",
  AppointmentConfirmForPractitioner = "AppointmentConfirmForPractitioner"
}

// Appointment Notification Codes must be in both enums

export enum AppointmentNotificationActionCode {
  AppointmentCreateForPatient = "AppointmentCreateForPatient",
  AppointmentCreateForPractitioner = "AppointmentCreateForPractitioner",
  AppointmentCreateSeriesForPatient = "AppointmentCreateSeriesForPatient",
  AppointmentCreateSeriesForPractitioner = "AppointmentCreateSeriesForPractitioner",
  AppointmentEditForPatient = "AppointmentEditForPatient",
  AppointmentEditForPractitioner = "AppointmentEditForPractitioner",
  AppointmentEditSeriesForPatient = "AppointmentEditSeriesForPatient",
  AppointmentEditSeriesForPractitioner = "AppointmentEditSeriesForPractitioner",
  AppointmentEditPatientDataForPatient = "AppointmentEditPatientDataForPatient",
  AppointmentEditSeriesPatientDataForPatient = "AppointmentEditSeriesPatientDataForPatient",
  AppointmentCancelForPatient = "AppointmentCancelForPatient",
  AppointmentCancelForPractitioner = "AppointmentCancelForPractitioner",
  AppointmentCancelSeriesForPatient = "AppointmentCancelSeriesForPatient",
  AppointmentCancelSeriesForPractitioner = "AppointmentCancelSeriesForPractitioner",
  AppointmentAdmitForPatient = "AppointmentAdmitForPatient",
  AppointmentAdmitForPractitioner = "AppointmentAdmitForPractitioner",
  AppointmentAttendForPatient = "AppointmentAttendForPatient",
  AppointmentAttendForPractitioner = "AppointmentAttendForPractitioner",
  AppointmentFinishForPatient = "AppointmentFinishForPatient",
  AppointmentFinishForPractitioner = "AppointmentFinishForPractitioner",
  AppointmentReminderForPatient = "AppointmentReminderForPatient",
  AppointmentReminderForPractitioner = "AppointmentReminderForPractitioner",
  AppointmentStartingReminderForPatient = "AppointmentStartingReminderForPatient",
  AppointmentStartingReminderForPractitioner = "AppointmentStartingReminderForPractitioner",
  AppointmentConfirmationReminderForPatient = "AppointmentConfirmationReminderForPatient",
  AppointmentConfirmForPatient = "AppointmentConfirmForPatient",
  AppointmentConfirmForPractitioner = "AppointmentConfirmForPractitioner"
}
