import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";

import { Model } from "./model/model";
import { NotificationConfigActionCode } from "./notificationConfigActionCode";

export interface EmailConfig extends Model {
  actionCode?: NotificationConfigActionCode[];
  recipientEmailList?: string[];
  subjectTemplate?: string;
  bodyTemplate?: string;
  waitDelay?: number;
  emailId?: string;
}

export const EmailConfigKey = getKeyObj<EmailConfig>(
  createHydratedMock<EmailConfig>()
);
