import React from "react";
import { Create } from "components/generic/Create/Create";
import { FC } from "@laba/react-common";
import { ExternalResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { WhatsappClientCreateEditForm } from "components/pages/NexupAdmin/resources/whatsappClient/whatsappClientForm/WhatsappClientCreateEditForm";

export const WhatsappClientCreate: FC = () => {
  return (
    <Create resource={ExternalResourceType.WhatsappClient}>
      <WhatsappClientCreateEditForm />
    </Create>
  );
};
