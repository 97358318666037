import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { ResourceForm } from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import { ExternalResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { WhatsappClient } from "@laba/nexup-api";
import { WhatsappClientCreateEditMainContent } from "components/pages/NexupAdmin/resources/whatsappClient/whatsappClientForm/WhatsappClientCreateEditMainContent";

export const tk = tkCP.adminPage[ExternalResourceType.WhatsappClient];

export const whatsappClientJsonToModel: JsonToModel<WhatsappClient> =
  getDefaultJsonToModel(draft => {
    draft.organizationId = undefined;
  });

interface WhatsappClientCreateEditFormProps {
  isEdit?: boolean;
}

export const WhatsappClientCreateEditForm: FC<
  WhatsappClientCreateEditFormProps
> = ({ isEdit }) => {
  const { t } = useI18n();
  return (
    <ResourceForm
      tabsConfig={[
        {
          label: t(tk.tabs.main),
          content: <WhatsappClientCreateEditMainContent isEdit={isEdit} />
        }
      ]}
    />
  );
};
