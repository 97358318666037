import { AppointmentNotificationActionCode } from "model/primitives/notificationConfigActionCode";
import { AppointmentNotificationConfigCodes } from "model/resource/appointment/appointment";

export const mapAppointmentNotificationConfigCodeToNotificationActionCode = (
  code: AppointmentNotificationConfigCodes,
  isPatient: boolean,
  isSeries: boolean
): AppointmentNotificationActionCode => {
  switch (code) {
    case AppointmentNotificationConfigCodes.Cancel:
      return isPatient
        ? isSeries
          ? AppointmentNotificationActionCode.AppointmentCancelSeriesForPatient
          : AppointmentNotificationActionCode.AppointmentCancelForPatient
        : isSeries
        ? AppointmentNotificationActionCode.AppointmentCancelSeriesForPractitioner
        : AppointmentNotificationActionCode.AppointmentCancelForPractitioner;

    case AppointmentNotificationConfigCodes.Create:
      return isPatient
        ? isSeries
          ? AppointmentNotificationActionCode.AppointmentCreateSeriesForPatient
          : AppointmentNotificationActionCode.AppointmentCreateForPatient
        : isSeries
        ? AppointmentNotificationActionCode.AppointmentCreateSeriesForPractitioner
        : AppointmentNotificationActionCode.AppointmentCreateForPractitioner;

    case AppointmentNotificationConfigCodes.Confirm:
      return isPatient
        ? AppointmentNotificationActionCode.AppointmentConfirmForPatient
        : AppointmentNotificationActionCode.AppointmentConfirmForPractitioner;

    case AppointmentNotificationConfigCodes.Admit:
      return isPatient
        ? AppointmentNotificationActionCode.AppointmentAdmitForPatient
        : AppointmentNotificationActionCode.AppointmentAdmitForPractitioner;
    case AppointmentNotificationConfigCodes.Attend:
      return isPatient
        ? AppointmentNotificationActionCode.AppointmentAttendForPatient
        : AppointmentNotificationActionCode.AppointmentAttendForPractitioner;
    case AppointmentNotificationConfigCodes.Edit:
      return isPatient
        ? isSeries
          ? AppointmentNotificationActionCode.AppointmentEditSeriesForPatient
          : AppointmentNotificationActionCode.AppointmentEditForPatient
        : isSeries
        ? AppointmentNotificationActionCode.AppointmentEditSeriesForPractitioner
        : AppointmentNotificationActionCode.AppointmentEditForPractitioner;
    case AppointmentNotificationConfigCodes.EditPatientData:
      return isSeries
        ? AppointmentNotificationActionCode.AppointmentEditSeriesPatientDataForPatient
        : AppointmentNotificationActionCode.AppointmentEditPatientDataForPatient;
    default:
      return isPatient
        ? AppointmentNotificationActionCode.AppointmentFinishForPatient
        : AppointmentNotificationActionCode.AppointmentFinishForPractitioner;
  }
};
