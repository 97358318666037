import React, { useEffect } from "react";
import { FC, ModelId, useI18n } from "@laba/react-common";
import { TextInput } from "components/generic/TextInput/TextInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { DateTimeInput } from "components/generic/DateTimeInput/DateTimeInput";
import { ExternalResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import {
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  WhatsappClientConnectionStatus,
  WhatsappClientParamsKey
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useSelector } from "react-redux";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { useReactHookFormInput } from "components/hook/useReactHookFormInput";

const tk = tkCP.adminPage[ExternalResourceType.WhatsappClient];

interface WhatsappClientCreateEditMainContentProps {
  isEdit?: boolean;
}

export const WhatsappClientCreateEditMainContent: FC<
  WhatsappClientCreateEditMainContentProps
> = ({ isEdit }) => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);

  const whatsappClientOrganizationId = useWatchResourceForm<ModelId>(
    WhatsappClientParamsKey.organizationId
  );

  const { onChange: onChangeWhatsappClientSenderId } =
    useReactHookFormInput<ModelId>({
      source: WhatsappClientParamsKey.senderId
    });

  const { onChange: onChangeWhatsappClientOrganizationId } =
    useReactHookFormInput<ModelId>({
      source: WhatsappClientParamsKey.organizationId
    });

  useEffect(() => {
    if (isEdit) return;

    if (workspaceOrganizationId) {
      onChangeWhatsappClientOrganizationId(workspaceOrganizationId);
    }
  }, [workspaceOrganizationId, onChangeWhatsappClientOrganizationId, isEdit]);

  useEffect(() => {
    if (isEdit) return;
    onChangeWhatsappClientSenderId(
      whatsappClientOrganizationId
        ? `sender-${whatsappClientOrganizationId}`
        : undefined
    );
  }, [whatsappClientOrganizationId, onChangeWhatsappClientSenderId, isEdit]);

  return (
    <>
      <TextInput
        disabled
        source={WhatsappClientParamsKey.id}
        label={t(tk.fields.id)}
      />
      <EnumSelectorInput
        source={WhatsappClientParamsKey.connectionStatus}
        label={t(tk.fields.status)}
        enumValue={WhatsappClientConnectionStatus}
      />
      <TextInput
        source={WhatsappClientParamsKey.phone}
        label={t(tk.fields.phone)}
      />
      <BooleanInput
        source={WhatsappClientParamsKey.enabled}
        label={t(tk.fields.enabled)}
      />
      <BooleanInput
        source={WhatsappClientParamsKey.backupEnabled}
        label={t(tk.fields.backupEnabled)}
      />
      <DateTimeInput
        source={WhatsappClientParamsKey.lastUpdatedDate}
        label={t(tk.fields.lastUpdateDate)}
        disabled
      />
      <OrganizationReferenceInput
        label={t(tk.fields.organizationId)}
        source={WhatsappClientParamsKey.organizationId}
        type={OrganizationCombinedType.Provider}
        extraFilters={{
          [OrganizationListQueryParamsKey.active]: true
        }}
        required
        defaultValue={workspaceOrganizationId}
        disabled={workspaceOrganizationId !== undefined || isEdit}
      />
      <TextInput
        source={WhatsappClientParamsKey.senderId}
        label={t(tk.fields.senderId)}
        disabled={isEdit}
      />
    </>
  );
};
