import React from "react";
import { FC } from "@laba/react-common";
import { Edit } from "components/generic/Edit/Edit";
import { ExternalResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { WhatsappClientCreateEditForm } from "components/pages/NexupAdmin/resources/whatsappClient/whatsappClientForm/WhatsappClientCreateEditForm";

export const WhatsappClientEdit: FC = () => {
  return (
    <Edit resource={ExternalResourceType.WhatsappClient}>
      <WhatsappClientCreateEditForm isEdit />
    </Edit>
  );
};
