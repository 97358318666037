import {
  createGetter,
  deleteGetter,
  deleteManyGetter,
  getKeyTargetParam,
  getListGetter,
  getManyGetter,
  getManyReferenceGetter,
  getOneGetter,
  updateGetter,
  updateManyGetter
} from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import {
  getWhatsappClientList,
  getWhatsappClient,
  editWhatsappClient,
  createWhatsappClient,
  WhatsappClientListQueryParamsKey,
  WhatsappClientListQueryParams,
  WhatsappClient
} from "@laba/nexup-api";
import { ExternalResourceType } from "./utils/resourceProviderGetter";
import { ResourceProviderTypes } from "./utils/resourceProviderTypes";

const getList = getWhatsappClientList;

const getOne = getWhatsappClient;

const update = editWhatsappClient;
const create = createWhatsappClient;

const getOrderParam = () => undefined;

const getTargetParams = getKeyTargetParam<WhatsappClientListQueryParams>(
  WhatsappClientListQueryParamsKey
);

export const whatsappClientProvider: ResourceProviderTypes<
  WhatsappClient,
  WhatsappClientListQueryParams
> = {
  resourceType: ExternalResourceType.WhatsappClient,
  getList: getListGetter(getList, getOrderParam),
  getOne: getOneGetter(getOne),
  getMany: getManyGetter(getList),
  getManyReference: getManyReferenceGetter(
    getList,
    getOrderParam,
    getTargetParams
  ),
  update: updateGetter(update),
  updateMany: updateManyGetter(update),
  create: createGetter(create),
  delete: deleteGetter(),
  deleteMany: deleteManyGetter()
};
